.header {
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
}
.header-player {
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.25);
}
.logo {
  width: 170px;
  height: 31px;
}
.button {
  background: #ffc000;
  border-radius: 30px;
  font-family: 'Fjalla One';
  color: #3a2c00;
  font-size: 16px;
  padding: 7px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  cursor: pointer;
}
.button-player {
  background: #ffc000;
  border-radius: 30px;
  font-family: 'Fjalla One';
  color: #3a2c00;
  font-size: 14px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
}
.button-promo {
  background: #45B3DF;
  border-radius: 30px;
  font-family: 'Merriweather Sans';
  color: #fff;
  font-size: 16px;
  padding: 12px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 0!important;
  margin-bottom: 0!important;
}
.button-later {
  background: #fff;
  border-radius: 30px;
  font-family: 'Merriweather Sans';
  color: #333374;
  font-size: 16px;
  padding: 12px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top:10px!important;
  margin-bottom: 0!important;
}
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #37145b;
  width: 100%;
  align-content: center;
  background-image: url('./assets/images/background-v2.png');
  background-size: contain;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}
.video-box {
  width: 100%;
  max-width: 400px;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  overflow: hidden;
  border: 0px solid #efbc5e;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}
.action-button {
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 15px;
  background-color: #E94359;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.promo-box {
  overflow: hidden;
  width: 125px;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.promo-item {
  position: absolute;
  z-index: 99px;
  margin-left: 70px;
  width: 150px;
}
.lottie-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
}
.disabled {
  opacity: 0.2 !important;
}
.waiting-box {
  height: 100%;
  width: 100%;
  align-content: center;
  justify-content: center;
  background-image: url('./assets/images/background-v2.png');
  background-size: cover;
  background-position: 50%;
  animation-name: zoom-in-zoom-out;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: 20s;
  animation-delay: 0s;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
.title {
  font-family: 'Fjalla One';
  color: #fff;
  font-size: 16px;
  margin: 10px auto;
  width: 80%;
  text-align: center;
  line-height: 150%;
}
.paragraph {
  font-family: 'Merriweather Sans';
  color: #fff;
  font-size: 16px;
  margin: 20px auto;
  width: 80%;
  line-height: 150%;
  text-align: center;
}

.circle-wrap {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;
  background: #37145b;
  border-radius: 50%;
  border: 1px solid #50227e;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
  width: 136px;
  height: 136px;
  border-radius: 50%;
  background: #37145b;
  margin-top: 6px;
  margin-left: 6px;
  position: absolute;
  z-index: 100;
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #fff;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 30s;
  transform: rotate(180deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.promo-item {
  animation-name: promo-bounce;
  animation-iteration-count: 5;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: 2s;
  animation-delay: 2s;
}
.img-button {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  cursor: pointer;
}

.img-button:hover {
  -webkit-transform: scale(1.1) rotate(24deg);
  transform: scale(1.1) rotate(24deg);
}

@keyframes promo-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1) translateY(0px);
  }
  50% {
    transform: scale(1.1, 1.1) translateY(-7px);
  }
  100% {
    transform: scale(1, 1) translateY(0px);
  }
}

@media only screen and (max-width: 1200px) {
  .promo-item {
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .promo-box {
    display: none;
  }
  .inner-box {
    justify-content: center;
  }
  .button-box {
    position: absolute;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 50px;
    z-index: 999;
    bottom: 10px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    background: rgba(55, 20, 91, 0.2);
    padding: 0;
    left: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .img-button img {
    width: 50px;
    height: 50px;
  }
}
